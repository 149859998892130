import ApiService from '@/common/api.service'

const newAnswer = {
  namespaced: true,
  state: () => ({
    errors: null,
    newAnswers: null,
    isLoading: false,
  }),
  getters: {
    // Fot get new answers
    getNewAnswers(state) {
      return state.newAnswers
    },
  },
  actions: {
    // For new answers
    fetchAnswers(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        let data = {}

        if (
          process.env.VUE_APP_ENVIRONMENT != 'production' ||
          content.preview == 'preview'
        ) {
          data = content
        } else {
          data = {
            data: content.data,
            code: content.code,
          }
        }

        ApiService.get(`/api/v2/candidat/${content.nit}/show`, data).then(
          response => {
            if (response.status === 200) {
              resolve(response)
              return response
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetchToken(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        let data = {}

        if (
          process.env.VUE_APP_ENVIRONMENT != 'production' ||
          content.preview == 'preview'
        ) {
          data = content
        } else {
          data = {
            data: content.data,
            code: content.code,
          }
        }

        ApiService.post(`/api/v2/candidat/validate-token`, data).then(
          response => {
            if (response.status === 200) {
              resolve(response)
              return response
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },

    fetchNextSection(context, content) {
      return new Promise((resolve, reject) => {
        let params = ['is_confirm='+content.isConfirm]
        if(content.isPreview) params.push('preview=preview')
        if(content.skipValidation) params.push('novalidate=true')

        let url = `/api/v2/candidat/${content.nit}/${content.tesId}/next-section`
        if(params.length) url += '?' + params.join('&')

        context.commit('setLoading', true)
        ApiService.init()
        ApiService.get(url).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    validateAllSectionTimer(context, content) {
      return new Promise((resolve, reject) => {
        let params = ['is_confirm='+content.isConfirm]
        if(content.isPreview) params.push('preview=preview')

        let url = `/api/v2/candidat/${content.nit}/${content.tesId}/validate-all-section-timer`
        if(params.length) url += '?' + params.join('&')

        context.commit('setLoading', true)
        ApiService.init()
        ApiService.get(url).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update answer
    updateAnswer(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/${form.nit}/update`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update answer
    updateCheckLaterQuestion(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/${form.nit}/update/check-later`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update answer connection failed
    updateAnswerConnectionFailed(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form[0].nit}/update/connectionfailed`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store open tab
    storeOpenNewTab(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/store-open-tab`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update active step
    updateActiveStep(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/update-active-step`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update time
    updateTime(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/update-time`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store hidden photo
    candidatPhoto(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/candidat-photo`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store hidden photo
    storeHiddenPhoto(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/store-hidden-photo`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store AI check
    storeAIEvidence(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/store-ai-evidence`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For check Quality AI
    aiCheckQuality(context, image) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.initAIProc()
        const formData = {
          image: image,
        }
        ApiService.post(`/api/face/quality`, formData, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
        ApiService.init()
      })
    },

    // For check Face AI
    aiCheckFace(context, image) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.initAIProc()
        const formData = {
          image: image,
        }
        ApiService.post(`/api/face/nface`, formData, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
        ApiService.init()
      })
    },

    aiCheckObject(context, image) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.initAIProc()
        const formData = {
          image: image,
          flag_return: true,
        }
        ApiService.post(`/api/hpcam/detect`, formData, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
        ApiService.init()
      })
    },

    // For store screen category
    storeScreen(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/candidat/store-screen-user`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store hidden screen capture
    storeHiddenScreenCapture(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/store-hidden-screen-capture`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store cideo played
    storeVideoPlayed(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/candidat/${form.nit}/store-video-played`,
          form,
          true
        ).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For store proctoring record
    async storeProctoringRecord(context, content) {
      ApiService.setClientHeader()
      ApiService.setHeaderMultipartFormData()

      const response = await ApiService.post(
        `api/v2/candidat/${content.nit}/store-proctoring-record`,
        content,
        true
      )
      return response.data
    },

    // For store monitoring share screen
    async storeMonitoringProctoShareScreen(context, content) {
      const response = await ApiService.post(
        `api/v2/candidat/store-monitoring-share-screen`,
        content,
        true
      )
      return response.data
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new answers
    setNewAnswers(state, data) {
      state.newAnswers = data
    },
  },
}

export default newAnswer
